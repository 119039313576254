<template>
  <div style="height: 100%">
    <v-toolbar flat dense>
      <v-spacer></v-spacer>
      <v-chip class="mr-1 searchChipWrap" style="max-width:550px" >
        <v-icon small color="grey">search</v-icon>
        <v-text-field
          placeholder="Search"
          class="mb-1 searchInput"
          hide-details
          rounded
          clearable
          dense
          style="margin-top: 2px"
          v-model="search"
        ></v-text-field>
        <v-tooltip top v-if="filterActive">
          <template v-slot:activator="{ on }">
            <v-btn
              small
              icon
              right
              color="redPop"
              @click="resetFilters"
              v-on="on"
            >
              <v-icon small>filter_alt_off</v-icon>
            </v-btn>
          </template>
          <span style="font-size: 12px">Clear Filters</span>
        </v-tooltip>
      </v-chip>
    </v-toolbar>
    <ag-grid-vue
      groupDisplayType="multipleColumns"
      :animateRows="true"
      :rowGroupPanelShow="'always'"
      :autoSizeStrategy="{
        type: 'firGridWidth',
        defaultMinWidth: 150,
        columnLimits: [],
      }"
      style="width: 100%; height: 95%"
      :enableRangeSelection="true"
      :allowContextMenuWithControlKey="true"
      :columnDefs="columns"
      :rowData="files"
      :suppressAggFuncInHeader="true"
      :defaultColDef="defaultColDef"
      :sideBar="true"
      :statusBar="statusBar"
      :initialState="initialState"
      :postProcessPopup="postProcessPopup"
      :getRowId="getRowId"
      :getContextMenuItems="getContextMenuItems"
      @state-updated="onStateUpdated"
      @grid-ready="onGridReady"
    >
    </ag-grid-vue>

    <v-dialog
      v-model="previewShipmentFile"
      width="90vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <ViewFile
        :key="previewKey"
        v-if="previewShipmentFile && selectedFile && selectedFile.id"
        @Cancelled="cancelFile"
        :fileId="selectedFile.id"
        @close="previewShipmentFile = false"
      />
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import { AgGridVue } from "ag-grid-vue";
import OrganisationName from "@/components/GridComponents/Organisation.vue";
import PortCell from "@/components/GridComponents/Port.vue";
import TableHeader from "@/components/GridComponents/TableHeader.vue";
import ViewFile from "../SystemComponents/PreviewFileModal.vue";

export default {
  components: {
    AgGridVue,
    agColumnHeader: TableHeader,
    OrganisationName,
    PortCell,
    ViewFile,
  },
  data: () => ({
    columns: [
      {
        headerName: "File No.",
        field: "fileNumber",
      },
      {
        headerName: "Vessel",
        field: "vesselVoyage",
      },
      {
        headerName: "Shipper",
        field: "shipperName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p && p.data && p.data.shipper
            ? { organisation: p.data.shipper }
            : null;
        },
      },
      {
        headerName: "Consignee",
        field: "consigneeName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p && p.data && p.data.consignee
            ? { organisation: p.data.consignee }
            : null;
        },
      },

      {
        headerName: "Carrier",
        field: "shippingLineName",
        cellRenderer: "OrganisationName",
        cellRendererParams: (p) => {
          return p && p.data && p.data.shippingLine
            ? { organisation: p.data.shippingLine }
            : null;
        },
      },
      {
        headerName: "POL",
        field: "portOfLoadValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "POD",
        field: "portOfDischargeValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfDischargeValue : null,
            name: p.data ? p.data.portOfDischargeCity : null,
          };
        },
      },
      {
        headerName: "Destination",
        field: "finalDestinationValue",
        cellRenderer: "PortCell",
        cellRendererParams: (p) => {
          return {
            locode: p.data ? p.data.portOfLoadValue : null,
            name: p.data ? p.data.portOfLoadCity : null,
          };
        },
      },
      {
        headerName: "ETD",
        field: "etd",
      },
      {
        headerName: "ETA",
        field: "eta",
      },
    ],
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: true,
      enableRowGroup: true,
      enableValue: true,
      enablePivot: true,
      flex: 1,
      minWidth: 100,
      cellClass: "d-flex align-center",
      cellStyle: { textAlign: "center", fontSize: "11px" },
    },
    files: null,
    fileTimeout: null,
    fileQuery: null,
    filterActive: false,
    filterSetting: null,
    gridApi: null,
    initialState: {},
    loading: false,
    previewShipmentFile: false,
    previewKey: 2000,
    search: null,
    searchTimeout: null,
    selectedFile: null,
    statusBar: {
      statusPanels: [
        {
          statusPanel: "agTotalRowCountComponent",
          align: "left",
        },
        {
          statusPanel: "agFilteredRowCountComponent",
        },
        {
          statusPanel: "agSelectedRowCountComponent",
        },
        {
          statusPanel: "agAggregationComponent",
        },
      ],
    },
  }),
  watch: {
    search(val) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(async () => {
        this.gridApi.updateGridOptions({ quickFilterText: val });
      }, 400);
    },
  },
  created() {
    if (
      localStorage.getItem(
        "bookingFileTableState_" + this.$store.state.currentOrg.id
      )
    ) {
      let state = JSON.parse(
        localStorage.getItem(
          "bookingFileTableState_" + this.$store.state.currentOrg.id
        )
      );
      if (typeof state === "object" && state !== null) {
        this.initialState = state;
      }
    }
  },
  methods: {
    cancelFile(id) {
      this.files = this.files.filter((x) => x.id != id);
      let findRow = this.gridApi.getRowNode(id);
      if (findRow) {
        this.gridApi.applyTransaction({ remove: [findRow.data] });
      }
      this.previewShipmentFile = false;
    },
    filterListener(event) {
      let filter = this.gridApi.getFilterModel();
      this.filterActive = Object.keys(filter).length > 0;
      this.filterSetting = filter;
    },
    getContextMenuItems(params) {
      let result = [];
      if (!params.node.group) {
        result = [
          {
            name: "View File",
            icon: '<span class="material-icons" unselectable="on" role="presentation" style="font-size: 16px; color: grey; margin-bottom: 0px; padding-bottom: 0px">launch</span>',
            action: () => {
              if (params.node && params.node.data && params.node.data.id) {
                this.viewShipmentFile(params.node.data);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          {
            name: "Add to Filter",
            icon: '<span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span>',
            action: () => {
              if (params.node && params.node.data) {
                let filter = this.gridApi.getFilterModel();
                if (filter[params.column.colId]) {
                  filter[params.column.colId].values.push(params.value);
                } else {
                  filter[params.column.colId] = {
                    values: [params.value],
                  };
                }
                this.gridApi.setFilterModel(filter);
              } else {
                this.$message.error(
                  "Please select a single row to view the file"
                );
              }
            },
          },
          "separator",
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      } else {
        result = [
          "autoSizeAll",
          "expandAll",
          "contractAll",
          "resetColumns",
          "copy",
          "copyWithHeaders",
          "export",
          "chartRange",
        ];
      }

      return result;
    },
    async getFiles() {
      if (this.fileTimeout) {
        clearTimeout(this.fileTimeout);
      }
      if (this.loading && this.fileQuery) {
        this.fileQuery.abort();
      }
      this.loading = true;
      this.fileTimeout = setTimeout(async () => {
        this.fileQuery = new AbortController();
        const signal = this.fileQuery.signal;
        this.files = await this.$API.getDraftFiles({
          // params: this.params,
          signal,
        });
        this.loading = false;
      }, 750);
    },
    getRowId(params) {
      return params.data.id;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.addEventListener("filterChanged", this.filterListener);
      this.getFiles();
    },
    onStateUpdated(state) {
      localStorage.setItem(
        "bookingFileTableState_" + this.$store.state.currentOrg.id,
        JSON.stringify(state.state)
      );
    },
    postProcessPopup(params) {
      if (params.type !== "columnMenu") {
        return;
      }
      const ePopup = params.ePopup;
      ePopup.style.top = "65px";
      ePopup.style.left =
        params.column.actualWidth / 2 + params.mouseEvent.left + "px";
    },
    async resetFilters() {
      this.gridApi.setFilterModel({});
    },
    viewShipmentFile(file) {
      this.selectedFile = file;
      this.previewKey++;
      this.previewShipmentFile = true;
    },
  },
};
</script>